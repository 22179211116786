import Backbone from "custom/backbone-bundle";
import { getApiUrl } from "utils/get-api-url";
import Component from "models/component";
import "js-file-manager/dist/jsfilemanager.min";
import PrototypeModel from "models/prototype-model";
import AssetPack from "./asset-pack";
import Metadata from "./metadata";
import save from "utils/save";
import { app } from "../app";
import { saveAs } from "file-saver";
import { encode, decode } from "utils/base64-encode-decode";
import { hasTaxonomy } from "utils/taxonomy";
import { unit } from "globals/unit";
import { clearHistory } from "globals/action-history";
import { checkFlag } from "utils/flags";
import { saveState, resetState, throttleSaveState } from "utils/state";
import { storeCompletion } from "utils/completions";
import { clearConsistentUUIDCache } from "utils/generate-uuid";
import checkStorage from "utils/check-storage";
import { user } from "globals/user";
import {
  translate,
  translateContent,
  translateInline,
} from "utils/localisation";
import BLANK_BLOCK from "fixtures/task-types/block-blank";

const GRADES = ["all", 1, 2, 3, 4, 5, 6];

import { AssetManifest } from "./asset-manifest/asset-manifest";
import { preloader } from "views/layouts/preloader/preloader";
import removeKeys from "utils/remove-keys";
import { rejectCallback } from "utils/error-handling";

// Storage key for clipboard functionality
export const CLIPBOARD_STORAGE_KEY = "coding-task-clipboard";

//main model
export default PrototypeModel.extend({
  relations: [
    {
      type: Backbone.HasMany,
      key: "components",
      relatedModel: Component,
      reverseRelation: {
        key: "task",
        includeInJSON: false,
      },
    },
    {
      type: Backbone.HasMany,
      key: "asset-packs",
      relatedModel: AssetPack,
      parse: true,
      includeInJSON: ["id"],
    },
    {
      type: Backbone.HasOne,
      key: "metadata",
      relatedModel: Metadata,
      parse: true,
    },
  ],

  // whenever a task gets published, the default values get applied before the save
  defaults: {
    completed: false,
    failed: false,
    locked: false,
    skipped: false,
    startTime: null,
    finishTime: null,
    hasUserChanges: false,
    answerAttempts: 0,
    metadata: {},
    "solution-shown": false,
  },

  url() {
    if (this.type === "user-app") {
      return getApiUrl(`shared/app/${this.id}`);
    } else if (user.canLoadUserProgress()) {
      return getApiUrl(`state/task/${this.id}`);
    } else {
      return getApiUrl(`app/task/${this.id}`);
    }
  },

  initialize() {
    // NOTE: we intentionally DON'T extend from PrototypeModel here as we don't want a default ID to be generated

    this.__manifest = undefined;

    this.on("answer-attempt", this.taskAttempted);
    this.on("change:completed", this.onTaskCompleted);
  },

  /**
   * marks this task as having been started by the user
   */
  start() {
    if (this.has("startTime")) {
      return; // task already started
    }

    // keep track of start time
    this.set("startTime", Number(new Date()));

    // We only want block-separation to happen the very first time a task is
    // loaded within a lesson.
    const blockCoder = this.getComponent("models/block");
    if (
      blockCoder &&
      !hasTaxonomy(this, "use-type.free-code") &&
      !hasTaxonomy(this, "use-type.user-generated")
    ) {
      blockCoder.get("input").set("requires-separation", true);
    }

    save();
  },

  /**
   * checks whether this task was completed
   */
  checkCompletion() {
    if (!this.get("completed")) {
      let completed = true;

      // check whether all tests have passed on components that have any
      this.get("components").forEach(component => {
        if (component.has("test-passing") && !component.get("test-passing")) {
          completed = false;
        }
      });

      this.set("completed", completed);
      this.unset("skipped");
    }

    save();
  },

  /**
   * flag this task as having failed
   */
  flagFailedTask() {
    this.set("failed", true);
  },

  /**
   * keep track of the number of times the user attempts to answer this step
   */
  taskAttempted() {
    this.set("answerAttempts", this.get("answerAttempts") + 1);
  },

  /**
   * skip this step
   */
  skip() {
    if (this.get("skipped")) {
      return;
    }

    this.set("skipped", true);
    this.unset("completed");
    this.taskCompleted();

    save();
  },

  /**
   * Automatically locks the task based on completion
   */
  async autoLock() {
    if (hasTaxonomy(this, "task-type.experimental")) {
      // experimental steps are never locked
      // NOTE: when the user changes the code or design, the step will change to completed:false
      this.set("locked", false);
    } else {
      const isLocked = Boolean(this.get("completed") || this.get("skipped"));
      this.set("locked", isLocked);
      if (isLocked) {
        clearHistory();
      }
    }
  },

  /**
   * Handles task completion
   */
  onTaskCompleted() {
    if (!this.fetched) {
      return; // don't check for completion if the task hasn't been fully fetched
    }

    const completed = this.get("completed");
    const skipped = this.get("skipped");
    const { lesson } = require("globals/lesson");

    if (completed || skipped) {
      // keep track of end time
      if (!this.has("finishTime")) {
        this.set("finishTime", Number(new Date()));
      }
    }

    this.autoLock();

    // we intentionally do NOT await for these as we don't care about whether
    // they succeed or not, and don't want to wait for possible timed out requests
    storeCompletion(this, lesson);
    saveState(this);
  },

  /**
   * Allow tasks to keep track of changes made by user applied to its deep nested data
   */
  onUserAction() {
    if (hasTaxonomy(this, "task-type.experimental")) {
      // NOTE: we intentionally DON'T save this on the model as we don't want this
      // property to be saved to the DB
      this.practiceTaskHasChanged = true;
      this.set("completed", false);
    }
    setTimeout(() => throttleSaveState(this), 0);
  },

  getShortName() {
    if (this.get("type") === "splash") {
      return "i";
    } else {
      // runtime-require to deal with circular dependencies
      const lesson = require("globals/lesson").lesson;
      const steps = lesson
        .get("tasks")
        .filter(task => task.get("type") != "splash");
      return steps.indexOf(this) + 1;
    }
  },

  // determines whether this task is editable by a content creators
  isContentEditable() {
    // 'free code' task have no content to be edited
    if (hasTaxonomy(this, "use-type.free-code")) {
      return false;
    }

    return true;
  },

  /**
   * save task state
   */
  save(attrs, options = {}) {
    attrs = this.toJSON(options); // cannot do partial saves - always do a full save
    options.attrs = attrs;

    // if a task has no components, then it hasn't been fully loaded and cannot be saved
    if (attrs.components.length === 0) {
      return;
    }

    // override back to defaults if we're saving a 'start point'
    if (options.isStartPoint) {
      attrs = Object.assign(attrs, this.defaults);
    }

    // asset packs cannot be saved here, they must be managed via the LMS
    delete attrs["asset-packs"];

    return Backbone.Model.prototype.save.call(this, attrs, options);
  },

  /**
   * Save a user created task
   */
  async myAppSave(options = {}) {
    this.get("metadata").resetAuthor();
    this.get("metadata").generateThumbnailData();
    this.setUserGenerated("save");

    if (user.isContentCreator()) {
      // we need to clear version history when an (example) app is saved by CC
      // so that has no paper-trail when users remix them
      this.get("metadata").set("previous_versions", []);
    }

    await new Promise((resolve, reject) => {
      options.success = () => resolve();
      options.error = () => rejectCallback(reject);
      // When saving a user app, we skip parsing blocks
      // so that block ID's are maintained,
      // allowing undo/redo history to keep working
      options.skipParse = true;

      this.save(null, options);
    });
  },

  /**
   * publish the current version of the task to the DB (as a content creator)
   */
  async publish(options = {}) {
    // clear any metadata that may have been set
    this.get("metadata").clear();
    this.set("isClone", false);

    // mark this save as being a 'start point'
    options.isStartPoint = true;

    // find and store inline translations so they can be added to POEditor by the api
    this.set("__inlineTranslations", this._findInlineTranslations());

    await new Promise((resolve, reject) => {
      options.success = () => resolve();
      options.error = () => rejectCallback(reject);

      this.save(null, options);
    });
  },

  // we have a special fetch so that we can dynamically import() only the
  // required models based on the task type
  async fetch() {
    // this model has already been fetched
    if (this.fetched) {
      await preloader.complete("task fetch");
      await preloader.complete("task components");
      await preloader.complete("task parse");
      return;
    }

    return new Promise((resolve, reject) => {
      this.sync("read", this, {
        success: async res => {
          await preloader.complete("task fetch");
          await this._ensureComponents(res);
          await preloader.complete("task components");
          clearConsistentUUIDCache();
          this.set(this.parse(res));
          await preloader.complete("task parse");
          this.trigger("sync", this, res, {});
          this.fetched = true;
          resolve();
        },
        error: rejectCallback(reject),
      });
    });
  },

  // ensures that all component models are available before sending the data off to backbone
  async _ensureComponents(data = {}) {
    return Promise.all(
      data.components
        .filter(component => Boolean(component))
        .map(component => {
          if (typeof component === "object" && component.model) {
            return this._importComponent(component.model); // if we have a valid component - import it
          } else if (data.original_item_id) {
            this.reload(); // if we somehow have an invalid component and we are using a save state, reset the save state
          }
        })
        .flat(),
    );
  },

  // import a component model
  // note: we have to hard-code these otherwise webpack has no way of knowing which files to bundle
  _importComponent(model) {
    switch (model) {
      case "models/instructions":
        return [
          import(
            /* webpackChunkName: "models.instructions" */ "models/instructions"
          ),
        ];
      case "models/video":
        return [import(/* webpackChunkName: "models.video" */ "models/video")];
      case "models/python":
        return [
          import(/* webpackChunkName: "models.python" */ "models/python"),
        ];
      case "models/html":
        return [import(/* webpackChunkName: "models.html" */ "models/html")];
      case "models/block":
        return [import(/* webpackChunkName: "models.block" */ "models/block")];
    }
  },

  /**
   * reloads this task from the network, essentially resetting it
   */
  async reload() {
    app.destroyLayout();
    await resetState(this, this.get("original_item_version"));

    const id = this.get("id");
    throttleSaveState.cancel();
    this.clear({ silent: true });
    this.set("id", id);

    // allow a new fetch
    this.fetched = false;
    await this.fetch();
    this.reset();

    // set new metadata
    this.set("metadata", new Metadata());

    app.setLayout();
  },

  /**
   * reset a task
   * if config is passed - it will be set to the model
   */
  reset(config) {
    this.unset("completed");
    this.set("locked", false);
    delete this.practiceTaskHasChanged;

    if (config) {
      //don't reset asset packs - these persist and must be changed via the LMS
      config["asset-packs"] = this.get("asset-packs").toJSON();
      this.set(config, { parse: true });
    }

    save();

    this.trigger("reset");
  },

  hasAccessToFreeCodeAssets() {
    return Boolean(
      (hasTaxonomy(this, "use-type.free-code") ||
        hasTaxonomy(this, "use-type.user-generated")) &&
        this.getComponent("models/block"),
    );
  },

  /**
   * fetch manifest data
   */
  async fetchManifest(options = {}) {
    if (options.hard) {
      delete this.__manifest;
    }

    if (this.hasAccessToFreeCodeAssets()) {
      const module = await import(
        /* webpackChunkName: "free-code-manifest" */
        "globals/free-code-manifest"
      );
      await module.manifestReady;
    }

    await Promise.all(this.get("asset-packs").map(pack => pack.fetch()));
  },

  /**
   * retrieve the manifest of this task
   * the manifest is a list of all available assets
   */
  async getManifest() {
    if (this.__manifest) {
      return JSON.parse(JSON.stringify(this.__manifest));
    }

    await this.fetchManifest();

    const data = {};

    let list = this.get("asset-packs").toJSON() || [];

    if (this.hasAccessToFreeCodeAssets()) {
      const module = await import(
        /* webpackChunkName: "free-code-manifest" */
        "globals/free-code-manifest"
      );
      list = list.concat(module.freeCodeManifest.toJSON());
    }

    // assign folders to all assets based on which asset pack they came from
    list.forEach(pack => {
      pack.assets.forEach(asset => {
        asset.folders = asset.folders || [];

        if (!data[asset.phaserKey]) {
          data[asset.phaserKey] = asset;
        }

        if (pack.name !== "All") {
          data[asset.phaserKey].folders.push(pack.name);
        }
      });
    });

    // store computed manifest as a flat list (used by Phaser preloader)
    this.__manifest = Object.values(data);

    // generate folder manifest for browsing assets
    this.__folderManifest = new AssetManifest();
    this.__folderManifest.generate(this.__manifest);

    return this.getManifest();
  },

  /**
   * find a specific entry from the manifest
   * this is the preferred method of fetching items from the manifest
   */
  async getManifestItem(key) {
    const manifest = await this.getManifest();
    let item = manifest.find(item => item.phaserKey === key);

    // fallback for legacy assets, use deprecated key property
    if (!item) {
      item = manifest.find(item => item.key === key);
    }

    item = item || null;

    // clone item to prevent modifying the original
    item = JSON.parse(JSON.stringify(item));

    return item;
  },

  // finds the component that uses a specific model
  getComponent(model) {
    return this.get("components").find(
      component => component.get("model") === model,
    );
  },

  toJSON(options) {
    const data = PrototypeModel.prototype.toJSON.call(this, options);

    // even though we try to strip as many id's as possible when calling toJSON
    // a task can never lose its id
    data.id = this.get("id");

    return data;
  },

  // download this task to file
  // TODO: support downloading python/html
  download(fileName) {
    const name = fileName ? fileName : this.get("name") || "app";
    if (this.getComponent("models/block")) {
      const data = this.copy({
        instructions: true,
        coder: true,
        history: false,
        grade: true,
      });
      data.metadata = {};
      let blob;

      //create file
      blob = new Blob([encode(JSON.stringify(data))], {
        type: "text/plain;charset=utf-8",
      });
      saveAs(blob, `${name}.block`);
    } else {
      const model =
        this.getComponent("models/html") || this.getComponent("models/python");
      const code = translateInline(
        model.exportRaw(
          model.get("language") === "html"
            ? { toAbsoluteUrl: true, pathPrefix: "html-iframe/" }
            : {},
        ) || " ",
      );

      // determine filetype
      let fileType = "txt";
      switch (model.get("language")) {
        case "python":
          fileType = "py";
          break;
        case "html":
          fileType = "html";
          break;
      }
      //create file
      const blob = new Blob([code], { type: "text/plain;charset=utf-8" });
      saveAs(blob, `${name}.${fileType}`);
    }
  },

  /**
   * Opens a file browser to upload a single file
   *
   * @param {Event} event The user interaction event
   * @todo Implement support for python/html
   */
  async upload(event) {
    try {
      event = event.originalEvent || event;
      // eslint-disable-next-line no-undef
      const file = await JSFileManager.pick({ event });
      let data;
      if (this.getComponent("models/block")) {
        await preloader.reset("task fetch", "task parse");
        data = await this.decodeFile(file);
        await this.importFromDecodedFile(data);
      } else {
        const raw = await file.getString();
        await this.importFromRawFile(raw);
      }
      clearHistory();
    } catch (error) {
      alert(`Error: ${error}`);
    }
  },

  /**
   * Opens a file browser to upload multiple files
   * The uploaded files are automatically saved under `my apps`
   *
   * @param {Event} event The user interaction event
   * @todo Implement support for python/html
   */
  async bulkUpload(event) {
    event = event.originalEvent || event;

    // eslint-disable-next-line no-undef
    const files = await JSFileManager.pick({ event, maxFiles: 999 });

    // sort files alphabetically
    files.sort((a, b) => (a.name > b.name ? 1 : -1));

    while (files.length > 0) {
      await preloader.reset("task fetch", "task parse");
      const file = files.shift();
      const data = await this.decodeFile(file);

      data.name = data.name || "none";
      data.name = `${file.name.replace(/\.block$/, "")}_${data.name}`;

      try {
        await this.importFromDecodedFile(data);
        this.get("metadata").prepFork(this);
        await this.fork(data.name);
      } catch (e) {
        files.length = 0;
      }
    }
  },

  /**
   * Decodes a file picked by JSFileManager
   *
   * @param {JSFile} file The file
   * @returns {JSON} data to be set on the task
   */
  async decodeFile(file) {
    const encoded = file.getString ? await file.getString() : await file.text();
    let data;

    if (/\.bc$/.test(file.name)) {
      // support for deprecated .bc files (saved as raw JSON)
      data = JSON.parse(encoded);
    } else if (/\.(block|block\.txt)$/.test(file.name)) {
      // otherwise decode base64
      data = JSON.parse(decode(encoded));
    } else {
      throw new Error("This type of file is not supported.");
    }

    // default to `all` grade if it's missing from the download data
    data.grade = data.grade || "all";

    // remove wall & background-code
    const coder = data.components.find(
      component => component.model === "models/block",
    );
    if (coder) {
      delete coder.snippets;
      delete coder["background-code"];
    }

    return data;
  },

  /**
   * Imports data from a decoded file into the current task
   * note: This clears the current task
   *
   * @param {JSON} data
   */
  async importFromDecodedFile(data) {
    app.destroyLayout();
    this.clear();
    this.set(BLANK_BLOCK);

    // delete components that aren't valid for the free coder
    if (Array.isArray(data.components)) {
      data.components = data.components.filter(component =>
        ["models/block", "models/python", "models/html"].includes(
          component.model,
        ),
      );
    }

    await this._ensureComponents(data);
    await this.paste({ data });
    if (!this.has("metadata")) {
      this.set("metadata", {});
    }
    this.get("metadata").resetAuthor();
    this.get("metadata").createUploadVersion();
    await preloader.complete("task fetch");
    await preloader.complete("task parse");
    app.setLayout();
  },

  /**
   * Imports data from a raw file into the current task
   * note: This clears the current task
   *
   * @param {JSON} data
   * @param {JSON} model
   */
  async importFromRawFile(raw) {
    app.destroyLayout();
    const lines = raw.split("\n").map(line => {
      return {
        text: line,
      };
    });
    let coder =
      this.getComponent("models/python") || this.getComponent("models/html");
    coder.set("lines", lines);
    app.setLayout();
  },

  // check whether data is on the clipboard
  hasClipboardData() {
    return Boolean(checkStorage() && localStorage[CLIPBOARD_STORAGE_KEY]);
  },

  // deletes the clipboard
  clearClipboard() {
    if (checkStorage()) {
      delete localStorage[CLIPBOARD_STORAGE_KEY];
    }
  },

  /**
   * copies the current components to the clipboard
   * Note - does not copy anything stored on the task itself such as name, description ...
   * TODO: add the ability to store to localStorage so copy/paste works between page navigation
   * @param  {Object}  options  configure what will be copied to the clipboard
   * @param  {Boolean} options.history  if set to false, the data will not be added to the clipboard.
   *                                    This allows for one-time copying without affecting the clipboard
   * @param  {Boolean} options.instructions  copies the instructions to the clipboard
   * @param  {Boolean|Object} options.metadata copies the metadata to the clipboard
   *                                           either accepts a boolean, in which case the current task's metadata will be copied
   *                                           or raw data, in which case this will be added to the clipboard
   * @param  {Boolean} options.coder copies the coder to the clipboard
   * @param  {Boolean} options.grade copies the task grade
   * @param  {Boolean} options.wall if set to false, remove the wall (must have coder:true to take effect)
   * @param  {Boolean} options.background-code if set to false, remove the background code (must have coder:true to take effect)
   */
  copy(options = {}) {
    const data = {
      components: [],
      type: this.get("type"),
      readme: this.get("readme"),
    };

    // set defaults
    Object.assign(data, this.defaults);

    // copy instructions
    if (options.instructions) {
      const instructions = this.getComponent("models/instructions");

      if (instructions) {
        data.components.push(instructions.toJSON());
      }
    }

    // copy metadata
    if (options.metadata) {
      if (typeof metadata === "boolean") {
        data.metadata = this.get("metadata").toJSON();
      } else {
        data.metadata = options.metadata;
      }
    }

    // copy code&stage
    if (options.coder) {
      let coder = this.getComponent("models/block");

      if (coder) {
        coder = coder.toJSON();

        if (options.wall === false) {
          delete coder.snippets;
        }

        if (options["background-code"] === false) {
          delete coder["background-code"];
        }

        data.components.push(coder);
      }
    }

    // copy grade
    if (options.grade) {
      const lesson = require("globals/lesson").lesson;
      data.grade = this.getGrade() || lesson.getGrade() || unit.getGrade();
    }

    // unless history is specifically set to false, put data on the clipboard
    if (options.history !== false && checkStorage()) {
      localStorage[CLIPBOARD_STORAGE_KEY] = JSON.stringify(data);
    }

    return data;
  },

  /**
   * replaces this task's components with whatever is on the clipboard
   * @param  {Object}  options                - configure what will be pasted
   * @param  {Boolean} options.data           - use arbitrary data instead of the clipboard
   * @param  {Boolean} options.keepSettings   - if true, the current coder settings will be maintained
   */
  paste(options = {}) {
    const inputData =
      options.data ||
      (checkStorage() ? localStorage[CLIPBOARD_STORAGE_KEY] : null);

    // keep track of what has and hasn't been imported
    const imported = {
      settings: false,
      type: false,
      readme: false,
      components: false,
      metadata: false,
      grade: false,
    };

    if (!inputData) {
      throw new Error("Failed to paste components - nothing on the clipboard");
    }

    const data =
      typeof inputData === "string" ? JSON.parse(inputData) : inputData;

    // store current coder settings if required and possible
    let settings;
    if (options.keepSettings) {
      const coder = this.getComponent("models/block");

      if (coder) {
        settings = coder.get("settings").toJSON();
      }
    }

    if (data.type) {
      this.set("type", data.type);
      imported.type = data.type;
    }

    if (data.readme) {
      this.set("readme", data.readme);
      imported.readme = data.readme;
    }

    if (data.components) {
      // remove all current components
      this.get("components").reset();

      // replace with data
      this.get("components").add(data.components);
      imported.components = data.components;
    }

    // replace metadata
    if (data.metadata) {
      this.set("metadata", data.metadata);
      imported.metadata = data.metadata;
    }

    // restore coder settings if required and possible
    if (settings && options.keepSettings) {
      const coder = this.getComponent("models/block");

      if (coder) {
        coder.set("settings", settings);
        imported.settings = settings;
      }
    }

    // set grade
    if (data.grade && !options.keepSettings) {
      this.setGrade(data.grade);
      imported.grade = data.grade;
    }

    Object.entries(imported).forEach(pair => {
      if (pair[1]) {
        // eslint-disable-next-line no-console
        console.log("IMPORTED:", pair[0], ":", pair[1]);
      } else {
        // eslint-disable-next-line no-console
        console.log("SKIPPED:", pair[0]);
      }
    });
  },

  /**
   * Auto-imports code to free code if there's anything on the clipboard
   */
  freeCoderAutoImport() {
    if (hasTaxonomy(this, "use-type.free-code") && this.hasClipboardData()) {
      this.paste({});
      this.clearClipboard();
    }
  },

  _findInlineTranslations() {
    const block = this.getComponent("models/block");
    const python = this.getComponent("models/python");
    const html = this.getComponent("models/html");

    if (block) {
      // TODO: implement inline translations for block coding
    }

    if (python) {
      return python._findInlineTranslations();
    }

    if (html) {
      return html._findInlineTranslations();
    }
  },

  /**
   * Checks if payload has any nested objects that contain '_KEY:'
   * replace '_KEY:' with it translation
   */

  translatePayload(data) {
    for (let key in data) {
      if (typeof data[key] === "object") {
        this.translatePayload(data[key]);
      } else if (Array.isArray(data[key])) {
        for (let item of data[key]) {
          if (typeof item === "object") {
            this.translatePayload(item);
          }
        }
      } else {
        data[key] = translateContent(data[key]);
      }
    }
  },

  /**
   * create a fork of this task
   * creating a clone of this entry in the database with a unique ID
   * WARNING: This modifies the data of the current task
   */
  fork(name) {
    if (!this.has("metadata")) {
      this.set("metadata", {});
    }

    if (!name) {
      name = this.get("name") || "";
    }

    this.setUserGenerated("fork");
    this.set("name", name);
    this.set("private", checkFlag("APPS_DEFAULT_PRIVATE"));
    let payload = this.toJSON();

    delete payload.id; // remove ID so it can be uploaded as a new item

    this.translatePayload(payload);

    return new Promise((resolve, reject) => {
      $.ajax(getApiUrl(`shared/app`), {
        data: JSON.stringify(payload),
        contentType: "application/json",
        type: "POST",
        success: res => {
          if (!res.metadata.thumbnail.src && payload.metadata.thumbnail) {
            // if the image hasn't had time to be uploaded to AWS yet, use raw data instead
            res.metadata.thumbnail.src = payload.metadata.thumbnail.raw_data;
          }

          return resolve(res);
        },
        error: rejectCallback(reject),
      });
    });
  },

  // get the grade of this task based on taxonomy
  getGrade() {
    const grade = GRADES.find(grade =>
      hasTaxonomy(this, `grade.grade-${grade}`),
    );

    return grade;
  },

  // changes the grade of the task
  // sets taxonomy
  // changes stage/code configs to defaults appropriate for said grade
  setGrade(grade) {
    const taxonomy = this.get("taxonomy");
    const version = taxonomy.__version || 1;

    GRADES.forEach(key => {
      const value = key === grade;
      switch (version) {
        case 1:
          taxonomy.grade[`grade-${key}`] = value;
          break;
        case 2:
          taxonomy.grade[`grade-${key}`] = { __value: value };
          break;
      }
    });

    this.set("taxonomy", taxonomy);

    const coder = this.getComponent("models/block");
    if (coder) {
      coder.setGradeDefaults(grade);
    }
  },

  /** Sets the taxonomy of this task to be user-generated */
  setUserGenerated(context) {
    const language = "block"; // TODO: hard-coded until we allow forking of python/html
    const loc = translate("locale");
    const grade = this.getGrade();

    // Taxonomy of example apps should be changed from LMS only
    // However, if we remix (fork) an app we need to reset taxonomy in all cases
    if (!hasTaxonomy(this, "task-type.example-app") || context === "fork") {
      this.set("taxonomy", {
        grade: this.get("taxonomy").grade,
        language: { [language]: true },
        locale: { [loc]: true },
        "use-type": { "user-generated": true },
      });
    }

    this.setGrade(grade);
  },

  parse(payload) {
    if (
      payload.isClone &&
      (hasTaxonomy(payload, "language.python") ||
        hasTaxonomy(payload, "language.html"))
    ) {
      removeKeys(payload, ["id", "_id"]);
    }
    return PrototypeModel.prototype.parse.call(this, payload);
  },
});
