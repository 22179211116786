export function rejectCallback(reject) {
  return (a, b) => {
    // jqXHR is sometimes first, sometimes second
    const response = a.statusText ? a : b;
    const error = new Error(response.statusText);
    for (const [key, value] of Object.entries(response)) {
      error[key] = value;
    }
    reject(error);
  };
}
